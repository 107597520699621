



























import Vue from 'vue';
import {Component, Prop, VModel, Watch} from 'vue-property-decorator';
import moment from 'moment';
import Job from '@/models/Job';
import User from '@/models/User';

@Component({
})

export default class DatePickerComponent extends Vue {
  /**
   * Array of exception to working / off-days
   */
  @VModel()
  private exceptions!: string[];
  /**
   * Start date of the job
   */
  @Prop()
  private startDate!: string;
  /**
   * End date of the job
   */
  @Prop()
  private endDate!: string;
  /**
   * workingDays
   */
  @Prop()
  private workdays!: number[];
  /**
   * Current Job
   */
  @Prop()
  private job!: Job;
  /**
   * Jobs that are overlapping with the current job
   */
  @Prop({default: []})
  public jobs!: Job[];
  /**
   * Year and month that is being displayed on the date picker
   */
  private yearMonth: string | null = null;
  /**
   * Username and color who are working in a specific month
   */
  private users: User[] = [];
  /**
   * Dates where multiple user are working at the same time
   */
  private conflicts: Set<string> = new Set();


  /**
   * Renders the exceptions on the date picker
   */
  @Watch('exceptions')
  @Watch('workdays')
  @Watch('yearMonth')
  @Watch('jobs')
  private renderExceptions() {
    this.conflicts = new Set();
    this.users = [];

    this.$nextTick(() => {
      const elements = document.querySelectorAll('.v-date-picker-table--date  .v-btn:not(.v-btn--disabled)');
      for (const element of elements) {
        const day = element.firstElementChild!.innerHTML.padStart(2, '0');
        const date = `${this.yearMonth}-${day}`;
        const dayOfWeek = moment(date).day();
        element.classList.remove('offDay', 'conflict');
        if (!this.workdays.includes(dayOfWeek)) {
          element.classList.add('offDay');
        }
        if (this.conflicts.has(date)) {
          element.classList.add('conflict');
        }
      }
    });
    this.$emit('update');
  }
  /**
   * Toggles exception from working day to off-day and vice versa
   */
  private toggleException(date: string) {
    if (this.exceptions.includes(date)) {
      this.exceptions.splice(this.exceptions.indexOf(date), 1);
    } else {
      this.exceptions.push(date);
    }
  }
  /**
   * Generates events for the date picker to show. Showing: conflicts
   */
  private genEvents(date: string): string {
    let color: string = '';
    if (this.isRelevantDay(date, this.startDate, this.endDate)) {
      // if date is relevant for this job
      this.jobs.forEach((job) => {
        // loop through other jobs with same startTime
        if ((this.isWorkingDay(job, date))) {
          // see if anybody is working on that day
          color = this.$colorHandler.getThemeColor(job.driver!.userColor);
          if (!this.users.find((user) => user.id === job.driver!.id)) {
            // add unique users
            this.users.push(job.driver!);
          }
          // check conflict
          if (this.isWorkingDay(this.job, date)) {
            this.conflicts.add(date);
          }
        }
      });
    }
    return color;
  }

  private isWorkingDay(job: Job, date: string) {
    if (job.timeSchedule.additionalDays.includes(date)) {
      return true;
    }
    if (!this.isRelevantDay(date, job.timeSchedule.startDate!, job.timeSchedule.endDate!)) {
      return false;
    }
    return job.timeSchedule.weekdays.includes(moment(date).day()) && !job.timeSchedule.daysOff.includes(date);
  }

  private isRelevantDay(date: string, start: string, end: string): boolean {
    if (!start  || !end) {
      return true;
    }
    return moment(date).isBetween(moment(start), moment(end));
  }
}
